import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
//import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import { GatsbyContext } from "../context/context"
import sublinks from "../constants/links"
import ServiceInfo from "../components/ServiceInfo"

const IndexPage = ({ location }) => {
  const { language, setLanguage, setLinks } = useContext(GatsbyContext)
  useEffect(() => {
    if (language === "ko") {
      navigate(`/`)
    }
  }, [language])

  // console.log("index.en IndexPage location=", location)
  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Hero />
      <ServiceInfo />
    </Layout>
  )
}

export default IndexPage
